"use client";

import { datadogRum } from "@datadog/browser-rum";
import { ApiError } from "@clayton-homes/home-designer/util/has-api-error";
import Image from "next/image";
import { ReactElement } from "react";
import NoResultsImage from "/assets/no-results.webp";

interface Props {
	apiError: ApiError;
	children?: ReactElement;
}

export default function ModelError({ apiError, children }: Props) {
	const noHomes = apiError.error === "missing_data";

	if (noHomes) datadogRum.addAction("No Results Found", apiError);
	else datadogRum.addError(apiError);

	return (
		<>
			<div className="wrapper-md text-center">
				<h2 className="text-headline-3 py-8">
					{noHomes
						? "Hmmm. We're not getting results."
						: "There was an error making your request."}
				</h2>

				{noHomes ? (
					children ? (
						children
					) : (
						<>
							<p>
								We&apos;re sorry. Either our program is not quite ready in your
								area or we don&apos;t have homes that match your search. You
								might try another zip code.
							</p>
							<p className="py-4">
								In the meantime, you can view more homes available near you at{" "}
								<a href="http://claytonhomes.com/" target="_blank">
									Claytonhomes.com
								</a>
								.
							</p>
						</>
					)
				) : null}

				<Image src={NoResultsImage} alt="No Results" className="mx-auto" />
			</div>
		</>
	);
}
